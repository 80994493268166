import React, { useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import Typist from "react-typist";
import { Button, makeStyles, TextField } from '@material-ui/core';
import { SubscriptionsRounded } from "@material-ui/icons";
import firebase from "../firebase";
import Configs from "../editable-stuff/configurations.json";
import "../App";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export default function ComingSoonPage() {
  const icons = Configs.icons;
  const classes = useStyles();
  const history = useHistory();

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicon",
    "socialicon",
    "socialicon",
    "socialicon",
    "socialicon"
  ]);

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];
    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconhover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicon";
      return setHoverstatus(newhoverStatus);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const subscribe = document.getElementById("subscribe").value;
    const db = firebase.firestore();
    db.collection("subscribed").doc(subscribe).set({
      email: subscribe
    })
    // .then(function() {
    //   console.log("Document successfully written!");
    // })
    // .catch(function(error) {
    //     console.error("Error writing document: ", error);
    // });
    document.getElementById("subscribe-form").reset();
    history.replace('/subscribed');
  };

  return (
    <div>
      <div
        id="comingsoon"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-1">Under Construction...</h1>
          <Typist className = "lead" cursor={{ hideWhenDone: true }}>
            <span className="lead">Welcome to my site! We are currently working on this site every minute of an hour.</span>
            <p>Enter your email below and we will notify you when it's ready.</p>
            Check out and follow our social media sites.
          </Typist>
          <div className="p-6" style={{paddingTop:"40px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <div>
            {/* <form autoCorrect="off" onSubmit={e => handleSubmit(e)} id="subscribe-form"
              style={{backgroundColor: "white", flexDirection: "column", padding: "15px", borderRadius: "10px", maxWidth:"650px", display:"inline-block", width:"100%",}}
            >
              <div className="form-row">
                <div className="form-group col-md-8">
                  <TextField required label="Email Address" type="email" id="subscribe" margin="normal" variant="outlined" placeholder="Email Address" style={{height: "56px", paddingTop: 0, width: "100%", }}/>
                </div>
                <div className="form-group col-md-2" style={{paddingTop: "13px",}}>
                  <Button variant="contained" color="primary" size="large" type="submit" className={classes.button} endIcon={<SubscriptionsRounded />}> Subscribe!</Button> 
                </div>
              </div>             
            </form> */}
          </div>
          <div className="container container-fluid text-center p-5" style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
            <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
            &nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </div>
  );
};
