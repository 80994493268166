// const firestoreService = require("firestore-export-import");
// const serviceAccount = require("./serviceAccountKey.json");

// const databaseURL = "https://website-a7b01.firebaseio.com"

// firestoreService.initializeApp(serviceAccount, databaseURL);

// firestoreService.restore("data.json");

import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBpByOlRc6STE0rdSCdgiLC7UQURwkI6XM",
    authDomain: "website-a7b01.firebaseapp.com",
    databaseURL: "https://website-a7b01.firebaseio.com",
    projectId: "website-a7b01",
    storageBucket: "website-a7b01.appspot.com",
    messagingSenderId: "1012922344948",
    appId: "1:1012922344948:web:e6bb22d8a1e55bcd0045a1"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;